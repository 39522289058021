<template>
	<div id="app">
		<div class="menu">
			<components-menu :menu="menu" :collapse="collapse"/>
		</div>
		<div class="app-content">
			<div class="app-header">
				<components-header :collapse="collapse" @toggleCollapse="toggleCollapse"/>
			</div>
			<div class="app-container">
				<div class="app-tags">
					<components-tags/>
				</div>
				<div class="content">
					<transition name="fade">
						<router-view v-if="isDev" :key="route_key"></router-view>
						<keep-alive v-else>
							<router-view :key="route_key"></router-view>
						</keep-alive>
					</transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ComponentsHeader from "@/components/layout/admin/Header.vue"
import ComponentsMenu from "@/components/layout/admin/Menu.vue"
import ComponentsTags from "@/components/layout/admin/Tags.vue"
import {auth} from "@/api/admin";
import {site_config} from "@/config";
import {isDev} from "@/common/other";
export default {
	components:{ComponentsHeader,ComponentsTags,ComponentsMenu},
	data(){
		return {
			collapse:false,
			menu:[
				{title:"控制台", icon:'el-icon-odometer', path:'/admin'},
				{title:"产品管理", icon:'el-icon-tickets',
					children:[
						{title:"产品列表",path:'/admin/product'},
						{title:"产品分类",path:'/admin/product/category'},
					]
				},
				{title:"体验店管理", icon:'el-icon-tickets',
					children:[
						{title:"体验店列表",path:'/admin/store'},
						{title:"预约列表",path:'/admin/store/book'},
					]
				},
				{title:"经销商管理", icon:'el-icon-tickets',
					children:[
						{title:"经销商列表",path:'/admin/agent'},
						{title:"联系列表",path:'/admin/agent/book'},
					]
				},
				{title:"内容管理", icon:'el-icon-tickets',
					children:[
						{title:"资料中心",path:'/admin/file'},
						{title:"视频管理",path:'/admin/video'},
						{title:"首页轮播图",path:'/admin/adv'},
						{title:"文章管理",path:'/admin/article'},
					]
				},
				{title:"用户管理", icon:'el-icon-tickets',
					children:[
						{title:"用户管理",path:'/admin/user'},
						{title:"售前/售后",path:'/admin/user/consult'},
					]
				},
				{title:"系统配置", icon:'el-icon-tickets',path:'/admin/config'},
				{title:"管理员",icon:'el-icon-user',path:'/admin/admin'},
			]
		}
	},
	computed:{
		route_key(){
			return this.$route.fullPath
		},
		meta(){
			return this.$route.meta;
		}
	},
	watch:{
		$route(){
			this.$store.commit('admin/setError',{})
		}
	},
	created() {
		document.title = `${site_config.title}管理系统`
		this.getAdminInfo();
	},
	methods:{
		isDev,
		toggleCollapse(){
			this.collapse = !this.collapse;
		},
		getAdminInfo(){
			auth.getLoginInfo().then((res)=>{
				this.$store.commit('admin/setAdminInfo',res.admin)
			})
		}
	}
}
</script>

<style lang="scss">
@import '@/assets/admin/css/reset.scss';
@import '@/assets/admin/css/ricktext.scss';
</style>
<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {transition: all .3s ease;}
.fade-enter-from, .fade-leave-to {opacity: 0;transition: all .3s ease;}
#app{position: relative;min-height: 100vh;display: flex;
	.app-content{flex: 1;overflow: hidden;
		.app-tags{margin-bottom: 15px;}
		.app-container{padding: 15px;background: #efefef;height:calc(100vh - 56px);overflow: hidden;overflow-y: auto;
			.content{background: #fff;padding: 15px;}
		}
	}
}
</style>
